import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {debounceTime, map, noop, Observable, Observer, of, Subscription, switchMap, tap} from "rxjs";
import {ILookupsModel} from "../../../../../core/models/lookups";
import {HttpClient} from "@angular/common/http";
import {default as apiLinks} from "src/app/utils/constants/api-links.json";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";
import {IPaginationWithDataModel} from "../../../../../core/models/pagination";
import {api} from "../../../../../core/utils/api";
import {Router} from "@angular/router";
import {FormControl} from "@angular/forms";
import {ApiService} from "../../../../../../../service/api.service";
import {DirectionService} from "../../../../../core/services/direction.service";
import {CommonConfigService} from "../../../../../../../utils/common/common-config.service";

@Component({
    selector: 'app-company-lookups',
    templateUrl: './company-lookups.component.html',
    styleUrls: ['./company-lookups.component.scss']
})
export class CompanyLookupsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() placeholder: string = ''
    @Input() label: string = ''
    @Input() id: string = ''
    @Input() isRequireSelect: boolean = false
    @Input() isFintechCompany: boolean = false;
    @Input() data?: string
    @Input() isAllCompanies : boolean  = false
    @Input() control: FormControl = new FormControl();
    @Input() variant: 'custom-select-search' | 'lookups' = 'lookups';
    @Output() onSelect: EventEmitter<TypeaheadMatch> = new EventEmitter<TypeaheadMatch>();
    private url = api.getServiceProviderLookups;
    private removeValueSubscription: Subscription = new Subscription();
    @Input() removeValue: Observable<void> = new Observable<void>();

    search?: string;
    suggestions$?: Observable<ILookupsModel[]>;
    errorMessage?: string;

    constructor(private http: ApiService, private router: Router, public directionService: DirectionService, private commonService: CommonConfigService) {
    }

    handleSelect(value: TypeaheadMatch) {
        this.onSelect.emit(value)
        this.control.setValue("")
    }

    ngOnInit(): void {

        if(this.isFintechCompany == true) this.url = apiLinks.api.mainService.company.searchFintechCompaniesBySearchCriteria;

        if(this.isAllCompanies == true) this.url =  apiLinks.api.mainService.company.searchCompaniesBySearchCriteria;

        this.removeValueSubscription = this.removeValue.subscribe(() => this.search = '');
        this.suggestions$ = new Observable((observer: Observer<string | undefined>) => {
            if (this.isRequireSelect) {
                this.control.setValue('')
            }
            observer.next(this.search);
        }).pipe(
            debounceTime(500),
            switchMap((query: string) => {
                if (query) {
                    return this.http.postT<IPaginationWithDataModel<ILookupsModel>>(
                        this.url, {
                            filters: this.getFilters(query),
                            page: 0,
                            size: 5,
                            sorts: this.getSortFilters()
                        }).pipe(
                        map((data: IPaginationWithDataModel<ILookupsModel>) => data && data.data.content || []),
                        tap({
                            next: () => noop,
                            error: (err) => {
                                this.errorMessage = err && err.message || 'Something goes wrong';
                            }
                        })
                    );
                }
                return of([]);
            })
        );
    }

    ngOnDestroy() {
        this.removeValueSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.data) {
            this.search = this.data
        }
    }

    getFilters(query : string){
        if(this.isFintechCompany || this.isAllCompanies){

        if(this.commonService.currentLanguage() == this.commonService.LANG_EN) {
            return [
                {
                    key: 'companyName',
                    operation: ':',
                    value: query
                }
            ]
        }else{
            return [
                {
                    key: 'companyNameAr',
                    operation: ':',
                    value: query
                }
            ]
        }

        }else{
            return [
                {
                    key: 'name',
                    operation: ':',
                    value: query
                }
            ]
        }
    }

    getSortFilters(){
        if(this.isFintechCompany || this.isAllCompanies){

            if(this.commonService.currentLanguage() == this.commonService.LANG_EN) {
                return [
                    {
                        key: 'companyName',
                        direction: 'DESC'
                    }
                ]
            }else{
                return [
                    {
                        key: 'companyNameAr',
                        direction: 'DESC'
                    }
                ]
            }
    
            }else{
                return [
                    {
                        key: 'name',
                        direction: 'DESC'
                    }
                ]
            }
    }
}
