import {AfterViewChecked, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ShareholdingsService} from "../../../service/shareholdings/shareholdings.service";
import {SuccessMessageDialogComponent} from "../success-message-dialog/success-message-dialog.component";
import {DialogMessageModel} from "../../../service/shared/dialog-message.model";
import {DomSanitizer, SafeStyle} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {CommonConfigService} from "../../../utils/common/common-config.service";
import {NewsletterService} from "../../../service/newsletter/newsletter.service";
import {AuthService} from "../../../service/auth/auth.service";
import {ToastrService} from "ngx-toastr";
import {cssObject} from "../../../utils/constants/css-object";

@Component({
  selector: 'app-newsletter-subscription-dialog',
  templateUrl: './newsletter-subscription-dialog.component.html',
  styleUrls: ['./newsletter-subscription-dialog.component.scss']
})
export class NewsletterSubscriptionDialogComponent implements AfterViewChecked{

  newsLetterFormGroup!: FormGroup;
  filesBaseUrl = environment.fileUrl;
  @ViewChild('newsLetterPopup') newsLetterPopup!: ElementRef;


  constructor(public matDialogRef: MatDialogRef<NewsletterSubscriptionDialogComponent>,
              private shareholdingsService: ShareholdingsService,
              public commonConfigService: CommonConfigService,
              public newsletterService: NewsletterService,
              private toastrService: ToastrService,
              public authService: AuthService,
              private sanitizer: DomSanitizer,
              private matDialog: MatDialog,
              private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.newsLetterFormGroup = this.initializeNewsLetterFormGroup()
    this.newsLetterFormGroup.get('languageCode')?.setValue(this.commonConfigService.currentLanguage());
  }

  ngAfterViewChecked() {
    this.commonConfigService.fixeAlignmentOfScaledElement(this.newsLetterPopup, cssObject.scale);
  }

  subscribeNewsLetter(){
    if (this.newsLetterFormGroup?.invalid) {
      this.newsLetterFormGroup.markAllAsTouched();
      return;
    }
    this.newsletterService.subscribeNewsLetter(this.newsLetterFormGroup.value).subscribe({
      next: () => {
        // this.successMsg = "successfulSubmittion";
      },
      error: (errorMessage) => {
        this.toastrService.error(errorMessage.error.message,errorMessage.error.errors);
      },
      complete: () => {
        this.newsLetterFormGroup?.reset();
        this.matDialogRef.close(true);
        sessionStorage.setItem('newsLetterPopupClosed', 'true');
        this.matDialog.open(SuccessMessageDialogComponent, {
          data: {
            heading: 'Thank You',
            message: 'You are now subscribed to',
            secondMessage: 'newsletter',
            linkName: 'Sharikat Mubasher',
            routerLink: '/'
          } as DialogMessageModel
        })

        if (this.authService.checkIfUserIsAuthenticated) {
           this.newsletterService.isUserSubscribedNewsletter = true;
        }

      }
    })
  }

  private initializeNewsLetterFormGroup() {
    return this.fb.group({
      fullName: [undefined, [Validators.required, Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]*$/), Validators.pattern('^[a-zA-Z ]*$')]],
      designation: [undefined, Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)],
      companyName: [undefined, Validators.pattern(/^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/)],
      email: ['', [Validators.required,Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/)]],
      languageCode: [undefined],
    });
  }

  newsLetterFormValidationMessages = {
    fullName: [
      {type: 'required', message: 'validationMsg.fullNameRequired'},
      {type: 'pattern', message: 'validationMsg.enterFullNameValidValue'},
    ],
    designation: [
      {type: 'pattern', message: 'validationMsg.enterDesignationValidValue'},
    ],
    email: [
      {type: 'required', message: 'validationMsg.emailRequired'},
      {type: 'pattern', message: 'validationMsg.validEmail'},
    ],
    companyName: [
      {type: 'pattern', message: 'validationMsg.enterCompanyNameValidValue'},
    ]
  }

  shouldShowError(input?: any, validation?: { type: string; message: string }): string {
    if (input?.hasError(validation?.type) && (input?.dirty || input?.touched)) {
      return 'block';
    } else {
      return 'none';
    }
  }

  close() {
    sessionStorage.setItem('newsLetterPopupClosed', 'true');
    this.matDialogRef.close(false)

  }

  public createBackgroundImageUrl(fileUrl: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustUrl('url(' + fileUrl + ')');
  }

}
