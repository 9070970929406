export const api = {
    login: '/auth-api/login',
    forgetPassword: '/auth-api/forget-password-request',
    titleLookups: '/public-api/lookup/getAllTitle',
    categoryLookups: '/public-api/lookup/SERVICE_CATEGORY',
    yearLookups: '/public-api/lookup/YEAR',
    currencyLookups: '/public-api/lookup/currencies',
    countryLookups: '/public-api/lookup/countries',
    sendOtp: '/public-api/register/generate-verification-email-otp',
    verifyEmail: '/public-api/register/verify-email-otp',
    register: '/public-api/register/buyer-with-user',
    spRegister: '/public-api/register/service-provider-with-user-for-stripe',
    updatePassword: '/common-api/password-change',
    resetPassword: '/auth-api/update-password-request',
    checkResetPasswordToken: '/auth-api/reset-password-verification?token=',
    getBuyerProfile: '/user-api/buyer',
    updateBuyerProfile: '/user-api/buyer/update',
    getSpProfile: '/user-api/service-provider/',
    updateSpProfile: '/user-api/service-provider/',
    getServiceProviderLookups: '/public-api/company/profile/search-service-providers',
    getProjectLookups: '/public-api/search-service-providers',
    getSpProfileView: '/public-api/company/profile/search-service-providers',
    spClaimOwnership: '/public-api/register/service-provider/claim-ownership',
    cityLookups: '/public-api/lookup/getAllCities',
    saudiCities: '/public-api/lookup/getCustomCities',
    businessTypeLookups: '/public-api/lookup/getAllBusinessType',
    developmentStageLookups: '/public-api/lookup/getAllDevelopmentStage',
    industryGroupLookups: '/public-api/lookup/getAllIndustrialClassificationLevelOne',
    getSpPrivateCompany: '/public-api/company/profile/search-companies',
    getSpSearch: '/public-api/search-service-providers',
    getSpProjects: '/public-api/search-sp-projects',
    getRelatedProjects: '/public-api/related-Projects/',
    createNewQuotation: '/user-api/quotation/buyer-request',
    getQuotationBuyer: '/user-api/quotation/buyer',
    getQuotationSp: '/user-api/quotation/service-provider',
    deleteQuotationBuyer: '/user-api/quotation/buyer/',
    updateQuotationBuyer: '/user-api/quotation/buyer',
    createQuotationResponse: '/user-api/quotation/service-provider/response',
    updateQuotationResponse: '/user-api/quotation/service-provider/response',
    deleteQuotationSp: '/user-api/quotation/service-provider/',
    getRequestBuyer: '/request/search-my-requests',
    getRequestSp: '/request/search-other-requests',
    addRespondToRequest: '/request/add-request-response',
    deleteRequestBuyer: '/request/delete-my-request/',
    deleteRequestResponse: '/request/delete-other-request-response/',
    subscription: "/user-api/stripe/subscription",
    subscriptionWeek: "/user-api/stripe/feature-payment",
    getCurrentSubscription: "/user-api/service-provider/user-active-subscriptions",
    searchOtherRequest: '/request/search-other-requests',
    searchMyRequest: '/request/search-my-requests',
    getPlans: '/public-api/subscription/sp-packages'

}
