<div aria-labelledby="pending-order_execute" aria-modal="true" class="modal offer-shares-modal execute fade show"
     data-bs-backdrop="static" data-bs-keyboard="false" id="PTOrderExecuteAcceptModal"
     role="dialog" style="display: block; padding-left: 0px;" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content scale-element mt-3">
      <div  class="modal-body bg-light" #newsLetterPopup>
        <div class="modal-wrapper pt-3 pb-5 px-5">
          <div class="modal-header d-flex justify-content-between">
            <div class="logo-en" *ngIf="commonConfigService.currentLanguage() == 'en'">
              <img alt="logo-en" src="assets/images/logo.png">
            </div>

            <div class="logo-ar mb-3" *ngIf="commonConfigService.currentLanguage() == 'ar'">
              <img class="logo-ar-img" alt="logo-ar" src="assets/images/logo_2_ar.png">
            </div>

            <button class="newsletter-close-button" [ngClass]="commonConfigService.currentLanguage() == 'en' ? '' : 'newsletter-close-icon-ar'" (click)="close()" type="button"><i
              class="fa-solid fa-x"></i></button>
          </div>
          <form (ngSubmit)="subscribeNewsLetter()" [formGroup]="newsLetterFormGroup" class="newsletter-form">
            <div class="mb-10">
              <div class="row">
                <div class="col-sm-12 col-lg-6 py-5 nl-description">
                  <h3 class="newsletter-main-heading p-3">{{"newsLetter.Subscribe To Our Newsletter" | translate}}</h3>
                  <p class="newsletter-description p-3" [ngClass]="commonConfigService.currentLanguage() == 'en' ? '' : 'description-ar'">
                    {{'newsLetter.description' | translate}}
                  </p>

                  <div class="newsletter-banner" >
                    <img src="assets/images/gif/newsletter.gif">
                  </div>
                </div>
                <div class="col-sm-12 col-lg-6">
                  <div class="container form-col p-5 d-flex flex-column">
                    <h3 class="text-center text-light details-heading">{{"Enter your Details" | translate}}</h3>
                    <div class="form-group">
                      <label for="fullName">{{ 'newsLetter.fullName' | translate}}</label>
                      <input class="" formControlName="fullName" id="fullName" placeholder="{{'newsLetter.enterFullName' | translate}}" type="text">
                      <ng-container
                        *ngFor=" let validation of newsLetterFormValidationMessages.fullName">
                        <div
                          [style.display]="shouldShowError(newsLetterFormGroup.get('fullName'),validation )"
                          class="invalid-feedback">
                          {{ validation.message | translate }}
                        </div>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label for="subscriberEmail">{{ 'Email' | translate}}</label>
                      <input class="" formControlName="email" id="subscriberEmail" placeholder="{{'newsLetter.enterEmail' | translate}}" type="email">
                      <ng-container
                        *ngFor=" let validation of newsLetterFormValidationMessages.email">
                        <div
                          [style.display]="shouldShowError(newsLetterFormGroup.get('email'),validation )"
                          class="invalid-feedback">
                          {{ validation.message | translate }}
                        </div>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label for="designation">{{ 'newsLetter.designation' | translate}}</label>
                      <input class="" formControlName="designation" id="designation" placeholder="{{'newsLetter.enterDesignation' | translate}}" type="text">
                      <ng-container
                        *ngFor=" let validation of newsLetterFormValidationMessages.designation">
                        <div
                          [style.display]="shouldShowError(newsLetterFormGroup.get('designation'),validation )"
                          class="invalid-feedback">
                          {{ validation.message | translate }}
                        </div>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <label for="companyName">{{ 'newsLetter.companyName' | translate}}</label>
                      <input class="" formControlName="companyName" id="companyName" placeholder="{{'newsLetter.enterCompanyName' | translate}}" type="text">
                      <ng-container
                        *ngFor=" let validation of newsLetterFormValidationMessages.companyName">
                        <div
                          [style.display]="shouldShowError(newsLetterFormGroup.get('companyName'),validation )"
                          class="invalid-feedback">
                          {{ validation.message | translate }}
                        </div>
                      </ng-container>
                    </div>
                      <button class="newsletter-subscribe-btn mt-4" type="submit">{{ 'newsLetter.subscribe' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</div>
