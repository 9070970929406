import {Inject, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, throwError} from 'rxjs';

import {UserModel} from './model/user.model';
import {ApiService} from '../api.service';
import {AuthResponseModel} from "./model/auth-response.model";
import {JwtHelperService} from '@auth0/angular-jwt';
import {LoginModel} from "./model/login.model";
import {default as apiLinks} from "src/app/utils/constants/api-links.json";
import {RoutersObject} from "../../utils/constants/routers-object";
import {UserTypesObject} from "../../utils/constants/user-types-object";
import {DOCUMENT} from "@angular/common";
import {constants} from "../../pages/sp-buyer-users/core/utils/constants";
import {CookieService} from "ngx-cookie-service";
import {UserService} from "../../pages/sp-buyer-users/core/services/user.service";

@Injectable({providedIn: 'root'})
export class AuthService {

  public user: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null);
  private tokenExpirationTimer: any;
  private routes = RoutersObject;
  private userTypeObj = UserTypesObject;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  public login(loginModel: LoginModel): Observable<any> {
    return this.apiService
      .postT<AuthResponseModel>(
        apiLinks.api.mainService.authentication.login,
        loginModel
      )
      .pipe(
        catchError(this.handleError),
        tap(resData => {
          this.handleAuthentication(
            resData.idToken
          );
        })
      );
  }

  public autoLogin() {
    if (!localStorage.getItem('_metaData')) {
      return;
    }
    const userData: {
      idToken: string;
    } = JSON.parse(localStorage.getItem('_metaData') ?? '');
    if (!userData) {
      return;
    }
    const loadedUser = new UserModel(userData.idToken);
    const expirationDuration = this.getExpireInTime(userData.idToken);

    if (loadedUser.getToken(expirationDuration)) {
      this.user.next(loadedUser);
      this.autoLogout(expirationDuration);
    }
  }

  get authToken(): string | null {
    if (!localStorage.getItem('_metaData')) {
      return null;
    }
    const userData: {
      idToken: string;
    } = JSON.parse(localStorage.getItem('_metaData') ?? '');
    if (!userData) {
      return null;
    }

    const loadedUser = new UserModel(userData.idToken);
    const expirationDuration = this.getExpireInTime(userData.idToken);
    return loadedUser.getToken(expirationDuration);
  }

  public logout(): void {
    this.user.next(null);
    this.cookieService.delete(constants.tokenName, '/');
    this.userService.userType$.next('guest');
    this.router.navigate(['auth/login']).then();
    localStorage.removeItem('_metaData');
    localStorage.removeItem('defaultRoute');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;

  }

  public autoLogout(expirationDuration: number): void {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  public getUserEmail(): string {
    const userData: {
      idToken: string;
    } = JSON.parse(localStorage.getItem('_metaData') ?? '');

    const token = this.jwtHelper.decodeToken(userData?.idToken);

    return token.sub ?? '';
  }

  public getUserTypeForAuthorization(): number {
    if (!localStorage.getItem('_metaData')) {
      return 0;
    }
    const userData: {
      idToken: string;
    } = JSON.parse(localStorage.getItem('_metaData') ?? '');

    const token = this.jwtHelper.decodeToken(userData?.idToken);

    return token.type ?? 0;
  }

  get checkIfUserIsAuthenticated(): boolean {
    if (!localStorage.getItem('_metaData')) {
      return false;
    }
    const userData: {
      idToken: string;
    } = JSON.parse(localStorage.getItem('_metaData') ?? '');
    if (!userData) {
      return false;
    } else {
      return true
    }
  }

  public getFirstTimeLogin(): number {
    const userData: {
      idToken: string;
    } = JSON.parse(localStorage.getItem('_metaData') ?? '');

    const token = this.jwtHelper.decodeToken(userData?.idToken);

    return token.isFirstTimeLogin ?? false;
  }

  public getDefaultAuthorizedDashboardRoute(userType?: number): string {
    let defaultRoute = '';

    switch (userType) {
      case this.userTypeObj.companyUser: // Company User Type ID
        defaultRoute = this.routes.dashboardCompanyUser
        break;
      case this.userTypeObj.individualShareholderUser: // Individual Shareholder User Type ID
        defaultRoute = this.routes.dashboardShareholderUser
        break;
      case this.userTypeObj.institutionalShareholderUser: // Institutional Shareholder User Type ID
        defaultRoute = this.routes.dashboardShareholderUser
        break;
      case this.userTypeObj.individualInvestorUser: // Individual Investor User Type ID
        defaultRoute = this.routes.dashboardInvestorUser
        break;
      case this.userTypeObj.institutionalInvestorUser: // Institutional Investor User Type ID
        defaultRoute = this.routes.dashboardInvestorUser
        break;
      case this.userTypeObj.buyerUser: // Buyer User Type ID
        defaultRoute = this.routes.dashboardBuyerUser
        break;
      case this.userTypeObj.serviceProviderCompanyUser: // Service Provider Company User Type ID
        defaultRoute = this.routes.dashboardServiceProviderCompanyUser
        break;
      default:
        defaultRoute = this.routes.login;
        break;
    }
    return defaultRoute;
  }

  get getUserTypeName(): string {
    const userType: number = this.getUserTypeForAuthorization();
    let userTypeName = '';

    switch (userType) {
      case this.userTypeObj.companyUser: // Company User Type ID
        userTypeName = 'Company'
        break;
      case this.userTypeObj.individualShareholderUser: // Individual Shareholder User Type ID
        userTypeName = 'Shareholder'
        break;
      case this.userTypeObj.institutionalShareholderUser: // Institutional Shareholder User Type ID
        userTypeName = 'Shareholder'
        break;
      case this.userTypeObj.individualInvestorUser: // Individual Investor User Type ID
        userTypeName = 'Investor'
        break;
      case this.userTypeObj.institutionalInvestorUser: // Institutional Investor User Type ID
        userTypeName = 'Investor'
        break;
      case this.userTypeObj.buyerUser: // Buyer User Type ID
        userTypeName = 'Buyer'
        break;
      case this.userTypeObj.serviceProviderCompanyUser: // Service Provider Company User Type ID
        userTypeName = 'Service Provider Company'
        break;
      default:
        userTypeName = '';
        break;
    }
    return userTypeName;
  }

  private handleAuthentication(idToken: string): void {
    const expirationIn = this.getExpireInTime(idToken);
    const user = new UserModel(idToken);
    this.user.next(user);
    this.autoLogout(expirationIn);
    localStorage.setItem('_metaData', JSON.stringify(user));

    let token = this.jwtHelper.decodeToken(idToken);
    if (token.type == UserTypesObject.buyerUser || token.type == UserTypesObject.serviceProviderCompanyUser) {
      this.cookieService.set(constants.tokenName, idToken, 365, '/');
      this.userService.token.next(idToken);
    }
  }

  private getExpireInTime(idToken: string): number {
    let token = this.jwtHelper.decodeToken(idToken);
    let expirationDate = new Date(token.exp * 1000);
    return expirationDate.getTime() - (new Date().getTime());
  }

  private handleError(errorRes: HttpErrorResponse) {
    let errorMessage = 'An unknown error occurred!';
    if (!errorRes.error.error || !errorRes.error.message) {
      return throwError(() => errorMessage);
    }
    switch (errorRes.error.message) {
      case 'Bad credentials':
        errorMessage = 'Invalid email or password';
        break;
    }
    return throwError(() => errorMessage);
  }

  public loginOnRegisterWithUser(idToken: string): void {
    this.handleAuthentication(idToken);
  }
}
