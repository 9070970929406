import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe, NgOptimizedImage} from '@angular/common';
import {MyCompaniesComponent} from './components/my-companies/my-companies.component';
import {FollowingCompaniesComponent} from './components/following-companies/following-companies.component';
import {MyRequestsComponent} from './components/my-requests/my-requests.component';
import {OtherRequestsComponent} from './components/other-requests/other-requests.component';
import {
  IndividualInvestorProfileDetailViewComponent
} from './components/individual-investor-profile-detail-view/individual-investor-profile-detail-view.component';
import {
  InstitutionalInvestorProfileDetailViewComponent
} from './components/institutional-investor-profile-detail-view/institutional-investor-profile-detail-view.component';
import {
  CompanyProfileDetailViewComponent
} from './components/company-profile-detail-view/company-profile-detail-view.component';
import {ViewFundingRoundsComponent} from './components/view-funding-rounds/view-funding-rounds.component';
import {ViewOrderBookComponent} from './components/view-order-book/view-order-book.component';
import {RouterLink} from "@angular/router";
import {ChangePasswordDialogComponent} from './dialogs/change-password-dialog/change-password-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthenticationModule} from "../pages/authentication/authentication.module";
import {OfferSharesDialogComponent} from "./dialogs/offer-shares-dialog/offer-shares-dialog.component";
import { ViewRequestDialogComponent } from './dialogs/view-request-dialog/view-request-dialog.component';
import { DeleteRequestDialogComponent } from './dialogs/delete-request-dialog/delete-request-dialog.component';
import { EmailComfirmationComponent } from './components/email-comfirmation/email-comfirmation.component';
import { RegisterInterestDialogComponent } from './dialogs/register-interest-dialog/register-interest-dialog.component';
import { TranslateModule} from "@ngx-translate/core";
import { NewsGroupComponent } from './components/news-group/news-group.component';
import { PreviousInvestmentInvestorComponent } from './dialogs/previous-investment-investor/previous-investment-investor.component';
import { KeyMembersComponent } from './dialogs/key-members/key-members.component';
import { BookNowDialogComponent } from './dialogs/book-now-dialog/book-now-dialog.component';
import { BookNowSuccessDialogComponent } from './dialogs/book-now-success-dialog/book-now-success-dialog.component';
import { InitiateARequestDialogComponent } from './dialogs/initiate-a-request-dialog/initiate-a-request-dialog.component';
import { CompanyAnnouncementDialogComponent } from './dialogs/company-announcement-dialog/company-announcement-dialog.component';
import { SuccessMessageDialogComponent } from './dialogs/success-message-dialog/success-message-dialog.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CustomdatePipe } from '../utils/pipes/customdatepipe.pipe';
import { CompanyUnfollowDialogComponent } from './dialogs/company-unfollow-dialog/company-unfollow-dialog.component';
import { UpdateFundingProfileDialogComponent } from './dialogs/update-funding-profile-dialog/update-funding-profile-dialog.component';
import { UpdateFundingRoundsDialogComponent } from './dialogs/update-funding-rounds-dialog/update-funding-rounds-dialog.component';
import { DeleteConfirmDialogComponent } from './dialogs/delete-confirm-dialog/delete-confirm-dialog.component';
import { CompanyFollowDialogComponent } from './dialogs/company-follow-dialog/company-follow-dialog.component';
import { RequestResponseDialogComponent } from './dialogs/request-response-dialog/request-response-dialog.component';
import { ViewRequestResponseDialogComponent } from './dialogs/view-request-response-dialog/view-request-response-dialog.component';
import {LinkHttpPipe} from "../utils/pipes/url.pipe";
import {
  RegisterInterestForOrderDialogComponent
} from "./dialogs/register-interest-for-order-dialog/register-interest-for-order-dialog.component";
import { NonExistentCompanyDialogComponent } from './dialogs/non-existent-company-dialog/non-existent-company-dialog.component';
import {ThemeModule} from "../theme/theme.module";
import {PeMyCompaniesComponent} from "./components/pe-my-companies/pe-my-companies.component";
import {MyCompaniesDashboardComponent} from "./components/my-companies-dashboard/my-companies-dashboard.component";
import {OpportunitiesComponent} from "./components/opportunities/opportunities.component";
import {MyOrdersComponent} from "./components/my-orders/my-orders.component";
import {MyTradesComponent} from "./components/my-trades/my-trades.component";
import {SlickCarouselModule} from "ngx-slick-carousel";
import { DeleteABidDialogComponent } from './dialogs/delete-a-bid-dialog/delete-a-bid-dialog.component';
import { AcceptOrRejectDealDialogComponent } from './dialogs/accept-or-reject-deal-dialog/accept-or-reject-deal-dialog.component';
import { NegotiateDialogComponent } from './dialogs/negotiate-dialog/negotiate-dialog.component';
import { BidDialogComponent } from './dialogs/bid-dialog/bid-dialog.component';
import { ExecuteOrderDialogComponent } from './dialogs/execute-order-dialog/execute-order-dialog.component';
import { ExecuteOrderConfirmationDialogComponent } from './dialogs/execute-order-confirmation-dialog/execute-order-confirmation-dialog.component';
import {
  ExecuteOrderRejectDialogComponent
} from "./dialogs/execute-order-reject-dialog/execute-order-reject-dialog.component";
import { OfferSharesConfirmationDialogComponent } from './dialogs/offer-shares-confirmation-dialog/offer-shares-confirmation-dialog.component';
import { DeleteSharesOfferDialogComponent } from './dialogs/delete-share-offer-dialog/delete-shares-offer-dialog.component';
import { AwsChimeMeetingComponent } from './components/aws-chime-meeting/aws-chime-meeting.component';
import {
  EditResponseDialogComponent
} from "./dialogs/edit-request-response-dialog/edit-response-dialog.component";
import { NewsletterSubscriptionDialogComponent } from './dialogs/newsletter-subscription-dialog/newsletter-subscription-dialog.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { AlertDialogComponent } from './dialogs/alert-dialog/alert-dialog.component';
import {CoreModule} from "../pages/sp-buyer-users/core/core.module";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import { PublicNewsCardComponent } from './components/public-news-card/public-news-card.component';
import { UiModule } from '../pages/sp-buyer-users/shared/ui/ui.module';
import { SpSubscriptionPackagesComponent } from './components/sp-subscription-packages/sp-subscription-packages.component';
import { RequestCallBackDialogComponent } from './dialogs/request-call-back-dialog/request-call-back-dialog.component';
import {LatestNewsSliderComponent} from "./components/latest-news-slider/latest-news-slider.component";
import { SearchCompaniesComponent } from './components/search-companies/search-companies.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { RequestQuotationForPublicUserDialogComponent } from './dialogs/request-quotation-for-public-user-dialog/request-quotation-for-public-user-dialog.component';
import {
  NewSuccessMessageDialogComponent
} from "./dialogs/new-success-message-dialog/new-success-message-dialog.component";

@NgModule({
  declarations: [
    MyCompaniesComponent,
    FollowingCompaniesComponent,
    AwsChimeMeetingComponent,
    MyRequestsComponent,
    OtherRequestsComponent,
    IndividualInvestorProfileDetailViewComponent,
    InstitutionalInvestorProfileDetailViewComponent,
    CompanyProfileDetailViewComponent,
    ViewFundingRoundsComponent,
    ViewOrderBookComponent,
    ChangePasswordDialogComponent,
    OfferSharesDialogComponent,
    ViewRequestDialogComponent,
    DeleteRequestDialogComponent,
    EmailComfirmationComponent,
    RegisterInterestDialogComponent,
    NewsGroupComponent,
    PreviousInvestmentInvestorComponent,
    KeyMembersComponent,
    BookNowDialogComponent,
    BookNowSuccessDialogComponent,
    InitiateARequestDialogComponent,
    CompanyAnnouncementDialogComponent,
    SuccessMessageDialogComponent,
    CustomdatePipe,
    ChangePasswordComponent,
    CompanyUnfollowDialogComponent,
    UpdateFundingProfileDialogComponent,
    UpdateFundingRoundsDialogComponent,
    DeleteConfirmDialogComponent,
    CompanyFollowDialogComponent,
    RequestResponseDialogComponent,
    ViewRequestResponseDialogComponent,
    LinkHttpPipe,
    RegisterInterestForOrderDialogComponent,
    NonExistentCompanyDialogComponent,
    RegisterInterestForOrderDialogComponent,
    MyCompaniesDashboardComponent,
    PeMyCompaniesComponent,
    OpportunitiesComponent,
    MyOrdersComponent,
    MyTradesComponent,
    DeleteABidDialogComponent,
    AcceptOrRejectDealDialogComponent,
    NegotiateDialogComponent,
    BidDialogComponent,
    ExecuteOrderDialogComponent,
    ExecuteOrderConfirmationDialogComponent,
    ExecuteOrderRejectDialogComponent,
    OfferSharesConfirmationDialogComponent,
    DeleteSharesOfferDialogComponent,
    EditResponseDialogComponent,
    NewsletterSubscriptionDialogComponent,
    NewsletterComponent,
    EditResponseDialogComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    PublicNewsCardComponent,
    SpSubscriptionPackagesComponent,
    RequestCallBackDialogComponent,
    AlertDialogComponent,
    LatestNewsSliderComponent,
    SearchCompaniesComponent,
    SplashScreenComponent,
    RequestQuotationForPublicUserDialogComponent,
    NewSuccessMessageDialogComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    MatDialogModule,
    ReactiveFormsModule,
    AuthenticationModule,
    TranslateModule,
    ThemeModule,
    SlickCarouselModule,
    FormsModule,
    CoreModule,
    TypeaheadModule,
    UiModule,
    NgOptimizedImage,
    CoreModule,
  ],
    exports: [
        NewsGroupComponent,
        CustomdatePipe,
        LinkHttpPipe,
        MyCompaniesDashboardComponent,
        PeMyCompaniesComponent,
        OpportunitiesComponent,
        MyOrdersComponent,
        MyTradesComponent,
        AwsChimeMeetingComponent,
        NewsletterComponent,
        PublicNewsCardComponent,
        SpSubscriptionPackagesComponent,
        LatestNewsSliderComponent,
        SearchCompaniesComponent,
        SplashScreenComponent

    ],
    providers: [DecimalPipe]
})
export class SharedModule {
}
